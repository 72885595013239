export default {
  edit: {
    name: 'edit'
  },
  view: {
    name: 'view'
  },
  both: {
    name: 'both'
  }
}
